(function() {

    var $status;
    var $text;

    $(function() {
        $status = $('#statement-filter-status');
        $text = $('#statement-filter-field');

        $status.change(filter);
        $text.keyup(filter);
    });

    function filter() {
        var status = $status.val();
        var text = escapeRegExpChars($text.val());
        var regexp = new RegExp(text, 'ig');

        $('#statements').find('.statement-row').each(function() {
            var $row = $(this);
            var hidden = true;

            $row.find('.filter-text').each(function() {
                if ($(this).text().match(regexp)) {
                    hidden = false;
                }
            });

            var rowStatus = $row.attr('data-status');
            if (status && rowStatus != status) {
                hidden = true;
            }

            if (hidden) {
                $row.addClass('d-none');
            } else {
                $row.removeClass('d-none');
            }
        });
    }

    /**
     * Подготовка строки для вставки в регулярное выражение (очистка от служебных символов)
     *
     * @param str
     * @return {*}
     */
    function escapeRegExpChars(str) {
        if (!str) {
            return '';
        }
        var escaped = str.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
        return escaped.replace(/\s+/g, '\\s+');
    }

})();
