(function() {

    $(function() {
        var $text = $('#expert-filter-field');

        $text.keyup(function() {
            var text = escapeRegExpChars($text.val());
            var regexp = new RegExp(text, 'ig');

            $('#experts').find('.expert-row').each(function() {
                var $row = $(this);
                var hidden = true;

                $row.find('.filter-field').each(function() {
                    if ($(this).text().match(regexp)) {
                        hidden = false;
                    }
                });

                if (hidden) {
                    $row.addClass('d-none');
                } else {
                    $row.removeClass('d-none');
                }
            });
        });
    });

    /**
     * Подготовка строки для вставки в регулярное выражение (очистка от служебных символов)
     *
     * @param str
     * @return {*}
     */
    function escapeRegExpChars(str) {
        if (!str) {
            return '';
        }
        var escaped = str.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
        return escaped.replace(/\s+/g, '\\s+');
    }

})();
